import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormInput from './FormInput';

const FormPasswordInput = forwardRef(({ status, ...props }, ref) => {
  const [isPreviewing, setIsPreviewing] = useState(false);

  return (
    <FormInput
      {...props}
      ref={ref}
      type={isPreviewing ? 'text' : 'password'}
      status={status}
      suffix={
        status === 'default' ? (
          <button
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              setIsPreviewing(!isPreviewing);
            }}
          >
            <FontAwesomeIcon
              icon={isPreviewing ? faEyeSlash : faEye}
              size="1x"
              className="text-md mt-1 text-gray-600"
              fixedWidth
            />
          </button>
        ) : null
      }
    />
  );
});

FormPasswordInput.propTypes = {
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
};

FormPasswordInput.defaultProps = {
  status: 'default',
};

export default FormPasswordInput;
