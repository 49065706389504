import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import qs from 'qs';

import config from 'config';
import { requiredEmail } from 'lib/validators';
import useBreakpoint from 'hooks/useBreakpoint';
import { useGlobal } from 'context/Global';
import { useAuth } from 'context/Auth';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import Link from 'components/common/Link';
import FormTitle from 'components/form/FormTitle';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormNote from 'components/form/FormNote';
import FormLabeledInput from 'components/form/FormLabeledInput';
import FacebookLogin from './FacebookLogin';

const errorMessages = {
  default: 'We were unable to log you in at this time. Please try again.',
  UserNotFound: 'No user was found with that email address',
  InvalidPassword: 'The password you entered was incorrect',
};

const messages = config('/validationMessages');
const validationSchema = yup.object().shape({
  email: requiredEmail,
  password: yup.string().required(messages.required),
});

const LoginForm = ({
  initialEmail,
  title,
  allowFacebookLogin,
  forgotPasswordParams,
  onSuccess,
}) => {
  const { addToast } = useGlobal();
  const mobile = !useBreakpoint('sm');
  const { login, isLoggedIn } = useAuth();

  const { control, formState, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: initialEmail,
      password: '',
    },
  });
  const { errors, isSubmitting } = formState;

  const currentEmail = watch('email');

  useEffect(() => {
    if (isLoggedIn) onSuccess();
  }, [isLoggedIn, onSuccess]);

  const forgotQueryString = qs.stringify({
    ...forgotPasswordParams,
    email: currentEmail,
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        await login(data);
      } catch (err) {
        setValue('password', '');

        const errorType = err.message.replace('GraphQL error: ', '');
        const errorMessage = errorMessages[errorType];

        if (config('/debug') && !errorMessage) {
          console.error('No custom message for error:', errorType);
        }

        addToast({
          id: 'login-error',
          type: 'error',
          duration: 'long',
          message: errorMessage || errorMessages.default,
        });
      }
    },
    [login, setValue, addToast]
  );

  return (
    <Card
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      depth="none"
      border={!mobile}
      padding={mobile ? 'none' : 'md'}
    >
      <FormTitle as="h1" className="text-left mb-6 mt-2 md:mt-0 text-2xl">
        {title}
      </FormTitle>
      {allowFacebookLogin && (
        <>
          <FacebookLogin />
          <div className="flex gap-x-4 items-center my-8">
            <div className="h-px bg-gray-400 grow" />
            <p className="shrink-0 uppercase font-medium text-xs">or</p>
            <div className="h-px bg-gray-400 grow" />
          </div>
        </>
      )}
      <FormFieldGroup>
        <FormNode>
          <div>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <FormLabeledInput
                  type="email"
                  isRequired
                  label="Email address"
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <div>
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => (
                <FormLabeledInput
                  type="password"
                  isRequired
                  label="Password"
                  autoFocus={!!initialEmail}
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => <FormNote status="error">{message}</FormNote>}
          />
        </FormNode>
        <FormNode>
          <p className="text-gray-700 leading-relaxed underline text-left">
            <Link
              href={`/forgot-password?${forgotQueryString}`}
              className="text-theme-primary hover:text-theme-primary-light transition-colors duration-200"
            >
              Forgot password?
            </Link>
          </p>
        </FormNode>
        <FormNode>
          <Button
            as="button"
            type="submit"
            color="green"
            className="font-medium w-full"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Signing in...' : 'Sign in'}
          </Button>
        </FormNode>
      </FormFieldGroup>
    </Card>
  );
};

LoginForm.propTypes = {
  initialEmail: PropTypes.string,
  title: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  forgotPasswordParams: PropTypes.object,
  allowFacebookLogin: PropTypes.bool,
  onSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  initialEmail: '',
  title: 'Sign in to Pledge It',
  forgotPasswordParams: {},
  allowFacebookLogin: false,
  onSuccess: () => {},
};

export default LoginForm;
