import type { PropsWithChildren } from 'react';
import FormTitle from 'components/form/FormTitle';

import cx from 'lib/cx';

type FormFieldGroupProps = PropsWithChildren<{
  className?: string;
  border?: boolean;
  title?: string;
}>;

const FormFieldGroup = ({
  children,
  className = '',
  border = false,
  title,
}: FormFieldGroupProps) => (
  <div
    className={cx('grid grid-cols-1 gap-y-6', className, {
      'sm:border border-gray-400 sm:p-8 rounded-xl': border,
    })}
  >
    {!!title && <FormTitle className="-mb-1">{title}</FormTitle>}
    {children}
  </div>
);

export default FormFieldGroup;
