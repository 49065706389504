import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import FacebookLoginButton from 'react-facebook-login/dist/facebook-login-render-props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

import config from 'config';
import { useGlobal } from 'context/Global';
import { useAuth } from 'context/Auth';
import Button from 'components/common/Button';

const FacebookLogin = ({ label }) => {
  const { addToast } = useGlobal();
  const { loginWithService } = useAuth();

  const [reauthorize, setReauthorize] = useState(false);

  const onResponse = useCallback(
    ({ accessToken, grantedScopes }) => {
      if (!accessToken) return;

      if (!grantedScopes.includes('email')) {
        addToast({
          id: 'facebook-login',
          type: 'error',
          message:
            'We require access to your email address to login using Facebook. Please re-authorize.',
          duration: 'long',
        });

        setReauthorize(true);

        return;
      }

      loginWithService({ service: 'facebook', accessToken });
    },
    [loginWithService, addToast]
  );

  return (
    <FacebookLoginButton
      appId={config('/seo/facebook/appId')}
      fields="name,email,picture"
      callback={onResponse}
      render={({ onClick, isDisabled, isProcessing }) => (
        <Button
          as="button"
          type="button"
          color="facebook"
          className="block w-full font-medium"
          onClick={onClick}
          disabled={isDisabled || isProcessing}
        >
          <FontAwesomeIcon icon={faFacebook} size="lg" className="mr-3" />
          {label}
        </Button>
      )}
      authType={reauthorize ? 'rerequest' : undefined}
      disableMobileRedirect
      returnScopes
    />
  );
};

FacebookLogin.propTypes = {
  label: PropTypes.node,
};

FacebookLogin.defaultProps = {
  label: 'Sign in with Facebook',
};

export default FacebookLogin;
