import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import cx from 'classnames';

import FormInput from './FormInput';
import FormPasswordInput from './FormPasswordInput';

const FormLabeledInput = forwardRef(
  ({ isRequired, label, value, type, className, ...props }, ref) => {
    const InputComponent = type === 'password' ? FormPasswordInput : FormInput;

    return (
      <div className={cx('relative', className)}>
        <InputComponent {...props} prefix={null} value={value} ref={ref} />
        <motion.label
          key="animatedLabel"
          initial={false}
          animate={value ? { scale: 0.7, y: -22 } : { scale: 1, y: 0 }}
          transition={{ type: 'tween' }}
          className={cx(
            'absolute left-3 top-2 mt-px px-1 transition-colors duration-200 pointer-events-none origin-left',
            {
              'text-gray-500': !value,
              'text-gray-800': value,
              'bg-white': value,
            }
          )}
        >
          {label}
          {isRequired && (
            <span className={cx({ 'text-red-600': value, 'text-red-300': !value })}> *</span>
          )}
        </motion.label>
      </div>
    );
  }
);

FormLabeledInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

FormLabeledInput.defaultProps = {
  value: '',
  isRequired: false,
  type: 'text',
  className: '',
};

export default FormLabeledInput;
