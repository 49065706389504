import { forwardRef, type LegacyRef, type PropsWithChildren } from 'react';

import cx from '@/lib/cx';

type FormNodeProps = PropsWithChildren<{ className?: string }>;

const FormNode = forwardRef(
  ({ className = '', children = null }: FormNodeProps, ref: LegacyRef<HTMLDivElement>) => (
    <div ref={ref} className={cx('grid grid-cols-1 gap-y-2', className)}>
      {children}
    </div>
  )
);

FormNode.displayName = 'FormNode';

export default FormNode;
